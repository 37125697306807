<template>
	<!-- nav -->
	<nav class="nav">
		<div class="nav__wrap">
			<a href="#" class="nav__btn nav__btn--prev">
				<i class="icon icon-left">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					최근 본 상품
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<!-- // nav -->
	<div id="container" class="container container--fit">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub mypage">
			<!-- content -->
			<section class="content">
				<div class="cart__top">
					<div class="item">
						<div class="allcheck">
							<div class="allcheck__all">
							</div>
							<button type="button" class="btn-delete">
								전체삭제
							</button>
						</div>
					</div>
				</div>
				<ul class="cart__list cart__list--large">
					<li>
						<div class="item">
							<div class="cart__wrap">
								<div class="cart__item">
									<!-- summary image -->
									<div class="cart__thumbnail">
										<img src="@/assets/images/temp-img-item02.jpg">
									</div>
									<!-- summary item -->
									<div class="cart__itembox">
										<div class="cart__itemdata">
											<p class="cart__option info-slogan">
												액티비티
											</p>
											<h3 class="cart__subtitle info-title multiline">
												[강원] 대관령 양떼 목장
											</h3>
											<div class="info-distance">
												서울 강남구 대치동 891-35
											</div>
										</div>
										<ul class="cart__info">
											<li>
												<div class="list__info-type">
													<span class="list__badge">예약</span>
												</div>
												<div class="list__info-price info-price">
													<div class="info-price__current">
														<strong>30,000</strong>원
													</div>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</li>
					<li>
						<!-- [D] 예약 마감일 경우 cart__wrap--disabled 클래스 추가 -->
						<div class="item">
							<div class="cart__wrap cart__wrap--disabled">
								<div class="cart__item">
									<!-- summary image -->
									<div class="cart__thumbnail">
										<img src="@/assets/images/temp-img-item02.jpg">
									</div>
									<!-- summary item -->
									<div class="cart__itembox">
										<div class="cart__itemdata">
											<p class="cart__option info-slogan">
												액티비티
											</p>
											<h3 class="cart__subtitle info-title multiline">
												[강원] 대관령 양떼 목장
											</h3>
											<div class="info-distance">
												서울 강남구 대치동 891-35
											</div>
										</div>
										<ul class="cart__info">
											<li>
												<div class="list__info-type">
													<span class="list__badge">예약</span>
												</div>
												<div class="list__info-price info-price">
													<div class="info-price__current">
														<strong>30,000</strong>원
													</div>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</li>
					<li>
						<div class="item">
							<div class="cart__wrap">
								<div class="cart__item">
									<!-- summary image -->
									<div class="cart__thumbnail">
										<img src="@/assets/images/temp-img-item02.jpg">
									</div>
									<!-- summary item -->
									<div class="cart__itembox">
										<div class="cart__itemdata">
											<p class="cart__option info-slogan">
												액티비티
											</p>
											<h3 class="cart__subtitle info-title multiline">
												[강원] 대관령 양떼 목장
											</h3>
											<div class="info-distance">
												서울 강남구 대치동 891-35
											</div>
										</div>
										<ul class="cart__info">
											<li>
												<div class="list__info-type">
													<span class="list__badge">예약</span>
												</div>
												<div class="list__info-price info-price">
													<div class="info-price__current">
														<strong>30,000</strong>원
													</div>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</section>
			<!-- //content -->
		</div>
	</div>
	<div id="navigation" class="navigation navigation--noborder">
		<div class="btns btns--centered">
			<button type="button" class="btn btn-confirm">예약하기</button>
		</div>
	</div>
</template>